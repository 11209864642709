/* font-family */
.sec.sec01 {
  position: relative;
  background: url(../img/top/sec01/bg.jpg) no-repeat center bottom;
  background-size: cover;
  padding: 50px 0 0;
  max-width: 1280px;
  margin: auto;
}

.sec.sec01:before,
.sec.sec01:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
}

.sec.sec01:before {
  left: 0;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}

.sec.sec01:after {
  right: 0;
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}

#main-catch {
  margin: 0 30px 280px;
}

#main-catch img {
  width: 40%;
  max-width: 146px;
}

nav#nav-page {
  position: relative;
  top: -30px;
}

nav#nav-page ul {
  display: flex;
  justify-content: center;
}

nav#nav-page ul li a img {
  position: relative;
  top: 0;
  max-width: 200px;
  transition: top 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

nav#nav-page ul li a:hover img {
  top: -10px;
}

.sec.sec02 {
  position: relative;
  z-index: 1;
  padding-bottom: 0;
  margin-top: -20px;
  background: url(../img/top/sec02/bg.png) repeat-x top center;
  background-size: 100% auto;
}

.sec.sec02 .img-wrap.iw01 {
  max-width: 840px;
}

.sec.sec03 {
  padding-bottom: 0;
}

.sec.sec03 .img-wrap.iw01 {
  max-width: 950px;
}

.sec.sec04 {
  padding-bottom: 0;
}

.sec.sec04 .img-wrap.iw01 {
  width: 80%;
  max-width: 600px;
}

nav#nav-sub {
  margin: 20px -5px 0;
}

nav#nav-sub ul {
  display: flex;
  flex-wrap: wrap;
}

nav#nav-sub ul li {
  width: 50%;
  padding: 5px;
}

nav#nav-sub ul li a {
  display: block;
}

.sec.sec05 {
  padding-bottom: 0;
}

.sec.sec06 {
  padding-bottom: 0;
}

.sec.sec07 {
  padding-bottom: 0;
}

.sec.sec09 {
  padding: 60px 0 240px;
  color: #fff;
  background: url(../img/top/sec09/bg.jpg) no-repeat center bottom;
  background-size: cover;
}

.sec.sec09 .msg p {
  font-size: 15px;
  margin: 30px 0 0;
}

.sec.sec09 .msg p span {
  display: inline-block;
}

.sec.sec10 {
  padding-bottom: 0;
}

.sec.sec10 .img-wrap.iw01 {
  max-width: 780px;
}

.img-wrap.panel01 {
  max-width: 844px;
}

.img-wrap.txt01 {
  width: 70%;
  max-width: 350px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.wideimg {
  position: relative;
  max-width: 1280px;
  margin: auto;
}

.wideimg i {
  display: block;
  position: absolute;
  top: 0;
  left: 5%;
  width: 20%;
  max-width: 130px;
  transform: translateY(-50%);
}

.answer {
  line-height: 0;
  width: 25%;
  max-width: 120px;
  margin: 30px auto 20px;
}

@media screen and (min-width: 600px) {
  nav#nav-page {
    top: -40px;
  }
  .sec.sec02 {
    margin-top: -30px;
  }
  nav#nav-sub ul li {
    width: 25%;
  }
  .wideimg i {
    left: 10%;
  }
}

@media screen and (min-width: 800px) {
  .sec.sec01 {
    text-align: left;
    padding: 70px 0 0;
  }
  nav#nav-page {
    top: -50px;
  }
  nav#nav-page ul li {
    padding: 0 2.5%;
  }
  .sec.sec02 {
    margin-top: -40px;
  }
  .sec.sec02 .btns {
    margin-top: -30px;
  }
  .sec.sec09 {
    text-align: left;
    padding: 100px 0 400px;
  }
  .sec.sec09 .msg p {
    font-size: 20px;
  }
  .img-wrap.txt01 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .wideimg i {
    left: 15%;
  }
  .answer {
    margin: 40px auto 30px;
  }
}

@media screen and (min-width: 1000px) {
  nav#nav-page {
    top: -60px;
  }
  .sec.sec02 {
    margin-top: -50px;
  }
  .wideimg i {
    left: 20%;
  }
}
